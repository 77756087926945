import {
  GenerateStatusCode,
  IAuth,
  authGateway,
} from '@wisestamp/auth-gateway-sdk';
import { AuthAutoRefresh } from './auth-auto-refresh.js';
import { currentUser } from '../session/user.js';
import { jwtDecode } from './jwt.js';
import { AccessTokenPayload, SessionTokenPayload } from './token.types.js';

type Resolver = () => void;

/**
 * Maintains healthy session and access tokens required for communicating with gateways
 *
 * The tricky part is allowing SDKs to use this class (IAuth) before the *thing* was initialized\
 * Follow initPromise/initResolver
 */
export class AuthState implements IAuth {
  private wsToken: string | undefined;
  private domainId: string | undefined;
  private sessionToken: string | undefined;
  private accessToken: string | undefined;
  private expireAt: number | undefined;

  /** Used to await the initialization before trying to serve SDKs */
  private initPromise: Promise<void>;
  /**
   * Resolves the above init promise
   * Had to convince ts that this is always initialized in constructor with the '!'
   */
  private initResolver!: Resolver;

  /** One refresh promise for all */
  private refreshPromise: Promise<string> | undefined;

  /** Auto refresh */
  private autoRefresh = new AuthAutoRefresh(this);

  private destroyed = false;

  constructor() {
    this.initPromise = new Promise((resolve, _reject) => {
      this.initResolver = resolve;

      // TODO: set timeout for rejection?
    });
  }

  public async init(wsToken: string, domainId?: string): Promise<void> {
    this.checkState();

    if (!wsToken?.trim()) throw new Error('auth.init: invalid wsToken');

    this.wsToken = wsToken;
    this.domainId = domainId;

    // resolve the init promise
    this.initResolver();

    // refresh the token (will generate)
    await this.refresh();
  }

  public async update(domainId: string): Promise<void> {
    this.checkState();

    if (!domainId?.trim()) throw new Error('auth: invalid domain');

    this.domainId = domainId;

    await this.refresh();
  }

  public async destroy() {
    this.checkState();

    const sessionToken = this.sessionToken;
    this.sessionToken = undefined;
    this.accessToken = undefined;
    this.wsToken = undefined;
    this.domainId = undefined;
    this.destroyed = true;
    this.autoRefresh.stop();

    await authGateway.revoke({ sessionToken });
  }

  private checkState() {
    if (this.destroyed) throw new Error('Trying to use a destroyed instance');
  }

  /** IAuth impl
   * returns the current access token if exist,
   * otherwise SDKs will call refresh()
   */
  public getCurrentAccessToken(): string | undefined {
    this.checkState();

    // Check expiration
    if (this.expireAt && Date.now() >= this.expireAt) return undefined; // The sdk will call refresh next

    return this.accessToken;
  }

  /**
   * IAuth impl
   * Tells the auth state machine that the access token is invalid
   * Called by SDKs when they get a 401
   *
   * Multiple calls to this method while obtaining an access token are
   * guarded and should all resolve on the same promise and the same token
   * @returns the access token
   */
  public async refresh(): Promise<string> {
    this.checkState();

    if (this.refreshPromise) {
      return this.refreshPromise;
    }

    this.refreshPromise = this.tryGetAccessToken();
    try {
      const accessToken = await this.refreshPromise;
      // what if it is undefined?
      return accessToken;
    } finally {
      // Very important!
      this.refreshPromise = undefined;

      this.expireAt = this.autoRefresh.start();
    }
  }

  private async tryGetAccessToken(): Promise<string> {
    this.accessToken = undefined;
    await this.initPromise;

    if (this.sessionToken) {
      try {
        const res = await authGateway.refresh({
          sessionToken: this.sessionToken,
          wsToken: this.wsToken,
          domainId: this.domainId,
        });

        this.sessionToken = res.sessionToken;
        this.accessToken = res.accessToken;

        return this.accessToken as string;
      } catch (e) {
        console.error('auth: failed to refresh token', e);

        // Fallback to generate
      }
    }

    // Either there is no session or refresh failed
    // try to generate
    let res = await authGateway.generate({
      wsToken: this.wsToken,
      domainId: this.domainId,
    });

    // try to connect without domain if domain is not found
    if (res.statusCode == GenerateStatusCode.INVALID_DOMAIN) {
      this.domainId = undefined;
      res = await authGateway.generate({
        wsToken: this.wsToken,
      });
    }

    this.sessionToken = res.sessionToken;
    this.accessToken = res.accessToken;

    this.notifyOthers();
    this.updateDomainInfo();

    return this.accessToken as string;
  }

  /** TODO: implement life-cycle events */
  private notifyOthers() {
    if (!this.sessionToken || !this.accessToken) return;

    const { userId, accountId } = jwtDecode<SessionTokenPayload>(
      this.sessionToken
    ).payload;

    const { permissions, entitlements, domainId } =
      jwtDecode<AccessTokenPayload>(this.accessToken).payload;

    currentUser.init(
      {
        accountId,
        domainId,
        userId,
      },
      {
        entitlements,
        permissions,
      }
    );
  }

  private updateDomainInfo() {
    if (!this.accessToken) return;

    const { domainId, domainKey } = jwtDecode<AccessTokenPayload>(
      this.accessToken
    ).payload;

    this.domainId = domainId;
    sessionStorage.setItem('domainId', domainId);
    //used by webapp
    sessionStorage.setItem('domainKey', domainKey);
  }
}
