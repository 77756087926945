import { registerApplication, start } from 'single-spa';
import {
  constructApplications,
  constructRoutes,
  constructLayoutEngine,
} from 'single-spa-layout';

import { reportMonitoringException } from '../monitoring';
import { isLocalDev } from '../misc';

export async function bootstrap(layout: unknown): Promise<boolean> {
  try {
    const routes = constructRoutes(layout as string); // not really a string, but RoutesConfig isn't exported
    const applications = constructApplications({
      routes,
      loadApp({ name }) {
        return import(/* @vite-ignore */ name);
      },
    });

    const layoutEngine = constructLayoutEngine({ routes, applications });
    applications.forEach(registerApplication);

    await loadPlugins();

    layoutEngine.activate();
    start();
  } catch (e) {
    console.error('bootstrap failed', e);
    reportMonitoringException(e);

    return false;
  }

  return true;
}

async function loadPlugins() {
  const plugins = getPlugins();
  const res = await Promise.allSettled(plugins.map(registerPlugin));

  // Allow plugins to fail when running locally
  if (isLocalDev) return;

  const rejectedCount = res.reduce(
    (count, promiseRes) => (count += promiseRes.status === 'rejected' ? 1 : 0),
    0
  );

  // continue load if only one module failed to load, the load error is reported to sentry in `registerPlugin`
  // The assumption is that someone broke a single module, no need to kill the whole SPA
  if (rejectedCount > 1) {
    // Several plugin failed to load, critical issue
    // The application should display an "internal error occured" message
    throw new Error(`${rejectedCount} plugins failed to load`);
  }
}

async function registerPlugin(name: string) {
  try {
    const plugin = await import(/* @vite-ignore */ name);
    await plugin.register();
  } catch (e) {
    console.warn(`Failed to register plugin ${name}`, e);
    reportMonitoringException(e);
    throw e;
  }
}

function getPlugins(): string[] {
  const theMapElement = document.head.querySelector('script[type="importmap"]');
  if (!theMapElement) throw new Error('failed to load importmap');

  const map = JSON.parse(theMapElement.innerHTML);
  const imports = map.imports as Record<string, string>;

  return Object.keys(imports).filter((name) => name.endsWith('-plugin'));
}
