import type { IAuth } from '@wisestamp/common';
import { jwtDecode } from './jwt.js';

export class AuthAutoRefresh {
  // Currently running refresh timeout if any
  private refreshTimeout: NodeJS.Timeout | undefined;

  private static intervalOverrideMs: number | undefined;

  /**
   * Provides a way to manipulate the interval for the refresh timer\
   * Created for a specific negative unit test
   */
  public static setIintervalOverride(intervalMs: number | undefined) {
    AuthAutoRefresh.intervalOverrideMs = intervalMs;
  }

  constructor(
    private authState: IAuth,
    private defaultIntervalMs = 55 * 1000
  ) {}

  /**
   * Start a refresh timout\
   * Stop current if any
   * @returns the expiration time (approximation)
   */
  public start(): number {
    const interval = this.getRefreshInterval();

    if (this.refreshTimeout) {
      console.info('auth-refresh: clearing prev access token refresh timeout');
      clearTimeout(this.refreshTimeout);
    }

    console.info('auth-refresh: refreshing in', interval, 'ms');
    this.refreshTimeout = setTimeout(async () => {
      console.info('auth-refresh: auto refreshing access token');
      try {
        this.refreshTimeout = undefined;
        await this.authState.refresh();
      } catch (e) {
        console.error('auth-refresh: auto refresh failed', e);
      }
    }, interval);

    return Date.now() + interval;
  }

  /**
   * Stop the running refresh timeout if any
   */
  public stop() {
    if (this.refreshTimeout) {
      clearTimeout(this.refreshTimeout);
    }
  }

  /**
   * Get access token refresh "timeout" in ms
   * Based on the tokens' expiration claim
   * As suggested by the jwt RFC: "provide for some small leeway",
   * Take a couple of second off the interval
   *
   * See RFC: https://datatracker.ietf.org/doc/html/rfc7519#section-4.1
   */
  private getRefreshInterval() {
    // Unit test ONLY
    if (AuthAutoRefresh.intervalOverrideMs)
      return AuthAutoRefresh.intervalOverrideMs;

    const accessToken = this.authState.getCurrentAccessToken();
    if (!accessToken) {
      return this.defaultIntervalMs;
    }

    const jwt = jwtDecode(accessToken);
    const { iat, exp } = jwt.payload;

    if (!iat || !exp) return this.defaultIntervalMs;

    const interval = (exp - iat) * 1000;

    // Refresh a few seconds before expiry (if longer than 5 sec for unit test and to be on the safe side)
    // if (intervalSec > 5 * 1000) return interval - 3 * 1000;

    if (interval <= 5000) return interval;

    // refresh 5 sec before estimated expiry
    return interval - 3000;
  }
}
