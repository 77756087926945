import type { IAuth } from '@wisestamp/common';
import { AuthLife } from './auth-life.js';

const authLife = new AuthLife();
let auth: IAuth | undefined = undefined;

export async function initAuth(): Promise<boolean> {
  try {
    const wsToken = getWsToken();

    if (!wsToken) return false;

    auth = await authLife.init();
    const domainId = sessionStorage.getItem('domainId') || undefined;
    await authLife.login(wsToken, domainId);
  } catch (e) {
    console.error('Exception during auth init', e);

    return false;
  }

  return true;
}

export function auth4Sdks(): IAuth {
  if (!auth) throw new Error('Too early: auth not initialized');

  return auth;
}

function getWsToken(): string | undefined {
  const wsTokenMarker = 'ws_token=';
  const cookies = document.cookie;
  const markerStartIndex = cookies.indexOf(wsTokenMarker);

  if (!markerStartIndex) return undefined;

  const tokenStartIndex = markerStartIndex + wsTokenMarker.length;
  let tokenEndMarker: number | undefined = cookies.indexOf(
    ';',
    tokenStartIndex
  );
  if (tokenEndMarker === -1) tokenEndMarker = undefined;

  return cookies.substring(tokenStartIndex, tokenEndMarker);
}
