export type JwtParts<TPayload> = {
  header: string;
  payload: TPayload & PayloadClaims;
  signature: string;
};

/**
 * a selection of the available props
 * Full list: https://datatracker.ietf.org/doc/html/rfc7519#section-4.1
 */
export type PayloadClaims = {
  // Expiration Time
  exp?: number;
  // Issued At
  iat?: number;
  // Subject
  sub?: string;
  // Issuer
  iss?: string;
  // Token ID
  jti?: string;
};

export function jwtDecode<TPayload>(jwt: string): JwtParts<TPayload> {
  const parts = jwt.split('.');

  return {
    header: JSON.parse(base64Decode(parts[0])),
    payload: JSON.parse(base64Decode(parts[1])),
    signature: parts[2],
  };
}

export function base64Decode(base64: string): string {
  // No Buffer in the browser - use atob
  return atob(base64);
}
